import axios from 'axios' // 引入axios
import qs from 'qs' // 引入qs
import store from '../store'
import Router from '../router/index'
import { Message } from 'element-ui';
import Cookies from 'js-cookie'


const baseURL = '//code.octopuscore.net'; // 正式地址
// const baseURL = 'http://10.60.201.39:8080'
// const baseURL = testUrl
const baseUploadURL = '//code.octopuscore.net'; // 文件上传域名
// const baseUploadURL = '//10.60.201.39:8080';
// const baseUploadURL = testUrl

let is401 = false; // 登录过期
let is409 = false; // 在其他地方登录

// const apiWebSocketUrl = 'wss://103.139.3.48:9876';
const apiWebSocketUrl = 'wss://code.octopuscore.net';
// const apiWebSocketUrl = `ws://10.60.201.39:8080`;
axios.defaults.baseURL = baseURL; //请求地址的域名（此处使用了代理所以直接填／apis


// 拦截响应response，进行错误处理
axios.interceptors.response.use(function (response) {
  // 成功处理
  return response;
}, function (error) {
  // 失败处理
  error.code = error.response.status
  switch (error.response.status) {
    case 400:
      error.message = '错误请求'
      break;
    case 401:
      error.message = '未授权，请重新登录'
      break;
    case 403:
      error.message = '拒绝访问'
      break;
    case 404:
      error.message = '请求错误,未找到该资源'
      break;
    case 405:
      error.message = '请求方法未允许'
      break;
    case 408:
      error.message = '请求超时'
      break;
    case 500:
      error.message = '服务器端出错'
      break;
    case 501:
      error.message = '网络未实现'
      break;
    case 502:
      error.message = '网络错误'
      break;
    case 503:
      error.message = '服务不可用'
      break;
    case 504:
      error.message = '网络超时'
      break;
    case 505:
      error.message = 'http版本不支持该请求'
      break;
    default:
      error.message = `连接错误${error.response.status}`
  }
  return Promise.reject(error);
});

/**
 * 发送请求
 * @param {*} Uri 请求 api
 * @param {*} params 请求参数
 * @param {*} method 请求类型 get / post 
 * @param {*} headers Object 请求头设置
 * @param {*} responseType string 请求相应类型，设置
 * @returns 
 */
async function request(Uri, params, method, headers, responseType) {
  // 这里主要是post方法的封装，get方法同理
  var lang = 'en';
  var hLang = 'en';
  //en_US 英文 CN 中文
  try {
    if(!params.lang){
      if(lang == 'en'){
        params.lang = 'en_US';
        hLang = 'en-US';
      } else if(lang == 'ja') {
        params.lang = 'ja_JP';
          hLang = 'ja-JP';
      } else{
        params.lang = 'CN';
        hLang = 'zh_CN';
      }
    }
  } catch (error) {
    
  }
  
  var options = {
    url: Uri,
  };
  options.method = method || 'post'
  if (options.method == 'get' || options.method == 'GET') {
    options.params = params;
  }
  if (options.method == 'post' || options.method == 'POST') {
    options.data = params;
  }
  if (options.config === 'formData') {
    options.headers = { 'Content-Type': 'multipart/form-data' }
  }

  let token = store.state.token;

  if (!!headers) {
    options.headers = {...headers, Authorization: token || ''}
  }else{
    options.headers = {Authorization: token}
  }
  if (!!responseType) {
    options.responseType = responseType
  }
  // options.headers['Accept-Language'] = hLang;
  /* else {
    options.data = qs.stringify(options.data)
  }*/
  return new Promise((resolve, reject) => {
    axios(options).then(res => {
      //  这里主要根据后来返回的数据作判断，请根据实际情况
      if (res.status === 200) {
        if (res.data.code == 210) {

          // console.log("res.data.code == 210：", res.data);
          //清除缓存的数据
          // localStorage.setItem('xndx_token', '');
          // localStorage.setItem('xndx_uid', '');
          // sessionStorage.setItem('xndx_token', '');
          // sessionStorage.setItem('xndx_uid', '');
          // window.location.href = '/';
        } else if (res.data.code == 401 && !!token) {
          if (!is401) {
            is401 = true;
            let msg = '登录已过期，请重新登录';
            if (lang == 'en') msg = 'Login has expired, please login again';
            Message.error(msg);
            store.dispatch('setToken', '');
            store.dispatch('setSecretKey', '');
            Cookies.remove('token');
            Cookies.remove('secretKey');
            setTimeout(() => {
              is401 = false;
              location.reload();
            }, 2000);
          }
          // resolve(res.data);
        } else if (res.data.code == 666) {
          // Message.error(res.data.msg);
          resolve(res.data)
          Router.back();
        } else if (res.data.code == 409) {
          if (!is409) {
            is409 = true;
            Message.error(res.data.msg);
            store.dispatch('setToken', '');
            store.dispatch('setSecretKey', '');
            Cookies.remove('token');
            Cookies.remove('secretKey');
            setTimeout(() => {
              is409 = false;
              location.href = '/';
            }, 2000);
          }
        } else {
          // console.log(Uri);
          // console.log(JSON.stringify(params));
          // console.log(`${tips || ''}`);
          // console.log(res.data);
          resolve(res.data)
        }
      } else {
        console.log(`status: ${res.data.status}, message: ${res.data.message}`);
        // this.$message.error(`status: ${res.data.status}, message: ${ res.data.message }`)
        
        reject(res.data)
      }
    }).catch(error => {
      // 显示拦截器对respone处理后的可读错误
      console.log(`${error.code} : ${error.message}`)
      // this.$message.error(`${error.code} : ${ error.message }`)
    })
  })
}

// 优化上传的模型
function uploadModelOptimize(url, baseFileId, successCallback) {
  axios({
    url: 'https://model-optimize.octopusworks.com/api/model/optimize',
    // url: 'http://10.60.201.39:8084/api/model/optimize',
    method: 'post',
    data: {
      // ossFilePath: Base64.encode(url),
      ossFilePath: url,
      baseFileId: baseFileId
    },
    headers: {
      Authorization: store.state.token,
    }
  }).then(res => {
    successCallback(res.data);
  }).catch(error => {
    console.error(error);
  });
}

export default { request, baseURL, baseUploadURL, apiWebSocketUrl, uploadModelOptimize }

//安装axios
//npm i axios

//安装qs
// npm i qs

//main.js
// import Request from '@/utils/request' // 引入封装的文件
// const { request } = Request
// Vue.prototype.$http = request // 挂载到全局上

//使用方法
/*

mounted () {
  that.$http('/app/index/index', {}).then(res =>{
    console.log(res);

  });
}

*/

